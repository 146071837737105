import React from 'react';
import {navigate} from '@reach/router';
import {ModalBody, Button} from 'reactstrap';

import Modal from 'components/Modal';
import OptInForm from 'components/OptInForm';
import ModalContext, {useModal} from 'contexts/modal';
import classNames from 'classnames';
import ShowIf from 'funnel-schedule/ShowIf';

export default function OptinModal({entry, ...props}) {
  const modalProps = useModal();
  const [navigateOnClose, setNavigateOnClose] = React.useState(false);
  const onComplete = React.useCallback(() => {
    modalProps.toggle();
    navigate('/masterclass/watch', {state: {fromEntry: entry}});
  }, []);
  const onStepComplete = React.useCallback((step) => step === 1 && setNavigateOnClose(true), []);
  const toggle = React.useCallback(() => {
    if (navigateOnClose) navigate('/masterclass/watch', {state: {fromEntry: entry}});
    modalProps.toggle();
  }, [navigateOnClose]);
  return (
    <Modal {...props} isOpen={modalProps.isOpen} toggle={toggle} size="lg">
      <ModalBody className="px-3 px-lg-4 pt-0 pb-4">
        <OptInForm onComplete={onComplete} onStepComplete={onStepComplete} id="modalOptInForm" entry={entry} />
      </ModalBody>
    </Modal>
  );
}

export function OptinModalTrigger({children = null, ...props}) {
  const className = classNames('btn-size-350 text-uppercase text-white text-condensed text-800', props.className);
  const modalProps = useModal();
  return (
    <Button
      color="cta"
      size="lg"
      {...props}
      className={props.classOverride ? props.classOverride : className}
      onClick={modalProps.toggle}
    >
      {children ? children : 'Reserve Your Free Spot Now'}
    </Button>
  );
}
