

  
// Template Map
export default {
  '/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404': require('/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/plants.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/plants.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-l/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-l/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-l/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-l/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-l/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-l/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-l/plants.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-l/plants.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-m/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-m/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-m/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-m/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-m/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-m/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-m/plants.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-m/plants.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/plants.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/plants.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/food-for-health-masterclass.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/food-for-health-masterclass.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/frs-upsell.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/frs-upsell.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join-discount.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join-discount.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join-fda.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join-fda.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join-special.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join-special.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/longevity.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/longevity.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/replay-cliff.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/replay-cliff.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/replay.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/replay.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/watch.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/watch.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/next.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/next.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/plants.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/plants.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/resources.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/resources.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/whole-ppt-alumni.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/whole-ppt-alumni.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/wlc-upsell.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/wlc-upsell.jsx').default
}

export const notFoundTemplate = '/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404'

