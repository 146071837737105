import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row} from 'reactstrap';
import {DateTime} from 'luxon';
import ReactMarkdown from 'react-markdown';
import LazyLoad from 'react-lazyload';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import Modal from 'components/Modal';
import classNames from 'classnames';
import {days, speakers} from 'components/SpeakerList/data.json';
import './SpeakerList.scss';

function Speaker({cols, id, modalLink, name, shortName, presentation, description, image, bio}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
  return (
    <Col className={classNames('speaker-item mb-3', id, cols)}>
      <Row className="speaker-list-item-row row d-flex align-items-stretch justify-content-center h-100">
        <Col xs="12" className="h-100">
          <div className="text-center d-flex flex-column h-100">
            {!modalLink ? (
              <a className="speaker-list-item-link mb-2" onClick={onClick}>
                <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
              </a>
            ) : (
              <div className="speaker-list-item-image mb-2">
                <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
              </div>
            )}
            <div className="speaker-details my-auto">
              <p className="speaker-name text-700 mb-0">{name}</p>
              <p className="speaker-presentation text-small mb-0">{presentation}</p>
            </div>
            {!modalLink ? (
              <p className="mt-3 mb-0">
                <Button block color="green" className="text-small text-white" onClick={onClick}>
                  Learn More
                </Button>
                <Modal isOpen={isOpen} toggle={toggle} size="lg">
                  <Container className="p-4" fluid>
                    <Row className="d-flex align-items-center justify-content-center mb-4">
                      <Col xs="8" lg="4" className="mb-4 mb-lg-0">
                        <img
                          className="speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle"
                          src={image}
                        />
                      </Col>
                      <Col xs="12" lg="8">
                        <div className="speaker-details text-center text-lg-left">
                          <h1 className="speaker-name">{name}</h1>
                          <h4 className="speaker-presentation">{presentation}</h4>
                          <p className="speaker-description mb-0">{description}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="speaker-bio-info">
                          <h5 className="speaker-bio-heading">More About {shortName}</h5>
                          <ReactMarkdown source={bio || '### Testing'} />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal>
              </p>
            ) : null}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

Speaker.defaultProps = {
  cols: 'col-6 col-sm-4 col-lg-3'
};

export default function SpeakerList({modalLink, cols}) {
  // Very basic, usually (but not always!) in the order of the JSON file
  return speakers.map((speaker) => <Speaker cols={cols} modalLink={modalLink} {...speaker} key={speaker.id} />);
}

export function SpeakersForDate({date, cols}) {
  // Show only speakers for date, Note: matching dates by string is very bad, should use date library
  return speakers
    .filter((speaker) => speaker.date.includes(date))
    .map((speaker) => <Speaker cols={cols} {...speaker} key={speaker.id} />);
}

export function SpeakersGroupByDay() {
  return days.map(({date, title, theme}) => (
    <Col xs="12" lg="6" className="d-flex align-items-stretch flex-column">
      <div className="background-light-gray text-center p-3 rounded mb-4">
        <h4 className="mb-1">
          {DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('MMMM d')} - {title}
        </h4>
        <p className="mb-0">{theme}</p>
      </div>
      <Row className="d-flex align-items-stretch justify-content-center h-100">
        <SpeakersForDate cols="col-6 col-sm-4" date={date} />
      </Row>
    </Col>
  ));
}

function SpeakerInfo({
  cols = 'col-12 col-md-4 col-lg-3',
  id,
  modalLink,
  name,
  shortName,
  presentation,
  description,
  image,
  bio,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
  return (
    <Col className={classNames(`speaker-info speaker-${id}`, cols)}>
      <Row className="speaker-info-row">
        <Col xs="12" className="speaker-info-col">
          <div className="speaker-info-wrap">
            <div className="speaker-info-image mb-md-2">
              {!modalLink ? (
                <Button className="speaker-list-item-link" color="img" onClick={onClick}>
                  <LazyLoad height={245} offset={100}>
                    <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
                  </LazyLoad>
                </Button>
              ) : (
                <div className="speaker-list-item-image">
                  <LazyLoad height={245} offset={100}>
                    <img className="speaker-headshot img-fluid img-speaker-shadow center-block rounded" src={image} />
                  </LazyLoad>
                </div>
              )}
            </div>
            {props.teaser ? (
              <div className="speaker-info-details mb-auto">
                <p className="speaker-name text-700 mt-md-2 mb-2 balance-text">{name}</p>
                {props.noTitle ? null : (
                  <p className="speaker-presentation balance-text text-small mb-3">
                    <i>{presentation}</i>
                  </p>
                )}
                <p className="speaker-teaser text-small balance-text mb-2">{description}</p>
              </div>
            ) : (
              <div className="speaker-info-details my-auto">
                <p className="speaker-name text-700 mb-0">{name}</p>
                {props.noTitle ? null : (
                  <p className="speaker-presentation balance-text text-small mb-0">{presentation}</p>
                )}
              </div>
            )}
            {!modalLink ? (
              <div className="speaker-info-more">
                <div className="mt-md-2 mb-0">
                  <Button className="speaker-info-btn" color="speaker-info" onClick={onClick} block>
                    <FontAwesomeIcon className="speaker-info-btn-icon d-md-none" icon={faInfoCircle} />
                    <span className="d-none d-md-inline">Learn&nbsp;</span>
                    More
                  </Button>
                </div>
                <Modal isOpen={isOpen} toggle={toggle} size="lg" className={props.modalClass}>
                  <Container className="px-sm-4 pt-0 pb-4" fluid>
                    <Row className="d-flex align-items-center justify-content-center mb-4">
                      <Col xs="10" lg="4" className="mb-4 mb-lg-0">
                        <LazyLoad height={245} offset={100}>
                          <img
                            className="speaker-headshot img-fluid img-speaker-shadow center-block rounded-circle"
                            src={image}
                          />
                        </LazyLoad>
                      </Col>
                      <Col xs="12" lg="8">
                        <div className="speaker-details">
                          <h3 className="speaker-name section-heading mb-2">{name}</h3>
                          <h5 className="speaker-presentation mt-0">{presentation}</h5>
                          <p className="speaker-description mb-0">{description}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="speaker-bio-info">
                          <h5 className="speaker-bio-heading section-heading">More About {shortName}</h5>
                          <ReactMarkdown source={bio || ''} />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Modal>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

export function SpeakerListInfo({speakerList = speakers, ...props}) {
  // Very basic, usually (but not always!) in the order of the JSON file
  return speakerList.map((speaker) => <SpeakerInfo {...speaker} key={speaker.id} {...props} />);
}
