import React, {useCallback, useState} from 'react';
import queryString from 'query-string';
import {Button} from 'reactstrap';
import classNames from 'classnames';
import {useUserState} from 'contexts/user';
import './CheckoutButton.scss';
import track from 'utils/track';
const {CHECKOUT_URL} = process.env;

export default function CheckoutButton({
  btnText = 'Buy Now',
  sku,
  slug,
  price,
  color = 'cta',
  size = 'lg',
  queryParams = {},
  children = undefined,
  ...props
}) {
  const userState = useUserState();
  const [loading, setLoading] = useState(false);
  const {location: {search = null} = {}} = typeof window === 'undefined' ? {} : window;
  const queryParamString = queryString.stringify(queryParams);
  const href = props.href ?? `${CHECKOUT_URL}/${slug}/${queryParamString ? `?${queryParamString}` : ''}`;
  const onClick = useCallback((e) => {
    let {href, target} = e.target;
    const {location: {search = null} = {}, EF = {}} = typeof window === 'undefined' ? {} : window;
    if (search && EF.getTransactionId) {
      const searchParams = queryString.parse(search);
      if (searchParams['affid'] && searchParams['oid']) {
        let url = new URL(href);
        url.searchParams.set('everflow_transaction_id', EF.getTransactionId(EF.urlParameter('oid')));
        href = url.toString();
      }
    }
    const openInNewWindow = target && target === '_blank';
    if (!openInNewWindow) {
      e.preventDefault();
    }
    const cb = () => !openInNewWindow && (window.location.href = href);
    setLoading(true);
    setTimeout(cb, 500);
    track(
      'Product Added',
      {
        sku,
        price,
        quantity: 1
      },
      null,
      cb
    );
  }, []);

  return (
    <Button className={props.className} color={color} size={size} {...props} onClick={onClick} href={href}>
      {loading ? 'Loading...' : children ? children : btnText}
    </Button>
  );
}
