import React, {useRef, useCallback, useEffect, useState} from 'react';
import {Col, Button} from 'reactstrap';
import classNames from 'classnames';
// @ts-ignore
import {Link} from 'react-scroll';

import useFixedFloating from 'utils/useFixedFloating';

import {OptinModalTrigger} from 'components/OptinModal';
import Video from 'components/Video';

import './ScrollingVideo.scss';

export default function ScrollingVideo({
  className = undefined,
  noFixed = false,
  closeBtnDark = false,
  bgColor = 'black',
  text,
  button,
  video
}) {
  const videoWrapper = useRef(null);
  const [isFixed, disableFixedListener] = useFixedFloating(videoWrapper);
  const [videoPlaying, setPlaying] = useState(video.playing === true);
  const dismiss = useCallback(() => {
    setPlaying(false);

    // @ts-ignore
    return disableFixedListener();
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (noFixed) return disableFixedListener();
  }, [noFixed]);

  const onPlay = useCallback(() => setPlaying(true), []);

  useEffect(() => {
    isFixed ? document.body.classList.add('has-fixed-header') : document.body.classList.remove('has-fixed-header');
  }, [isFixed]);

  return (
    <div className={classNames('header-video-wrapper', className)} ref={videoWrapper}>
      <div className={classNames('header-video-inner')}>
        <div className={classNames(isFixed ? 'header-video-fixed' : 'header-video-start')}>
          {isFixed ? (
            <Button
              className={classNames('btn-header-video-close', closeBtnDark && 'btn-header-video-close-dark')}
              onClick={dismiss}
            >
              <span aria-hidden="true">×</span>
            </Button>
          ) : null}

          <div
            className={classNames(
              'header-video-background',
              `background-${bgColor}`,
              isFixed && 'header-video-fixed-background'
            )}
          >
            <div className={classNames('header-video-container', isFixed && 'container')}>
              <div
                className={classNames('header-video-row', isFixed && 'row justify-content-center align-items-center')}
              >
                <div className={classNames('header-video-col', isFixed && 'col-sm-10 col-md-5 col-xl-4 px-0 px-sm-3')}>
                  <Video playing={videoPlaying} onPlay={onPlay} {...video} />
                </div>
                {isFixed && (
                  <Col sm="10" md="7" xl="8" className="py-md-3 px-0 px-sm-3">
                    <div className={classNames('header-video-cta', `text-left`)}>
                      {text && <div className="d-none d-md-inline">{text}</div>}
                      <div className={classNames('header-video-button')}>
                        {button.optin ? (
                          <OptinModalTrigger btnText={button.text} />
                        ) : (
                          <Link
                            className="btn btn-cta"
                            activeClass="active"
                            spy={true}
                            offset={button.offset ?? -100}
                            to={button.scrollToSection}
                            smooth={true}
                            href="#"
                          >
                            {button.text}
                          </Link>
                        )}
                      </div>
                    </div>
                  </Col>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
