import {TestimonialBubble} from 'components/Testimonial';
import React, {useState} from 'react';
import {Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption} from 'reactstrap';
import './TestimonialCarousel.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import {faAngleRight} from '@fortawesome/pro-regular-svg-icons/faAngleRight';

export default function TestimonialCarousel({list, bubbleColor = 'white', args = null}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === list.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? list.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = list.map((listItem, i) => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={'slide' + i}>
        <TestimonialBubble bgColor={bubbleColor} id={listItem} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={false}
      className="testimonials-carousel d-flex align-items-center"
      {...args}
    >
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      {slides}
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      <CarouselIndicators items={list} activeIndex={activeIndex} onClickHandler={goToIndex} />
    </Carousel>
  );
}
