import React from 'react';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faCheck} from '@fortawesome/pro-light-svg-icons';
import './IconListCL.scss';

export default function IconListCL({
  className = undefined,
  listItemClass = undefined,
  number = false,
  icon = undefined,
  iconColor = 'green',
  iconAlignment = 'center',
  children
}) {
  return (
    <ul className={classNames(`iconListCL`, `icon-list-${iconColor} fa-ul`, className)}>
      {React.Children.map(children, (child, i) => {
        return (
          <li key={i} className={classNames(`iconListCLItem`, `d-flex`, `align-items-${iconAlignment}`, listItemClass)}>
            {number ? (
              <div className="icon-list-number-wrap fa-layers fa-li">
                <FontAwesomeIcon
                  className="icon-list-number-circle"
                  //@ts-ignore
                  icon={faCircle}
                />
                <div className="icon-list-number-value fa-layers-text text-800">{i + 1}</div>
              </div>
            ) : (
              <FontAwesomeIcon
                className={classNames(`iconListCLIcon`, `fa-li text-${iconColor}`)}
                icon={icon ? icon : faCheck}
              />
            )}
            <div className="icon-list-item-content">{child}</div>
          </li>
        );
      })}
    </ul>
  );
}
