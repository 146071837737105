import React from 'react';
import {Col, Row} from 'reactstrap';
import classNames from 'classnames';
import './ImageCardCL.scss';

export default function ImageCardCL({
  type = 'default',
  bgColor = 'white',
  boxShadow = 'black-50',
  textColor = 'black',
  marginBottom = '4',
  className = undefined,
  children,
  image,
  imageAlt,
  imageFit = 'fill',
  imagePosition = 'center',
  imageColLg = '4',
  textColLg = '8',
  verticalColLg = '6'
}) {
  return (
    <>
      {type === 'vertical' ? (
        <Col
          lg={verticalColLg}
          className={classNames(`ImageCardCLVertical`, `d-flex`, `flex-column`, `mb-${marginBottom}`, className)}
        >
          <div
            className={classNames(
              `ImageCardCLVerticalInner`,
              `d-flex`,
              `flex-column`,
              `flex-grow-1`,
              `rounded`,
              `background-${bgColor}`,
              `text-${textColor}`,
              `box-shadow-${boxShadow}`
            )}
          >
            <div className={classNames(`ImageCardCLVerticalImageWrap`, `rounded-top`)}>
              <img
                className={classNames(`ImageCardCLVerticalImage`, `rounded-top`, `img-${imageFit}`)}
                src={image}
                alt={imageAlt}
              />
            </div>
            <div className={classNames(`ImageCardCLVerticalText`, `d-flex`, `flex-column`, `flex-grow-1`)}>
              <div className={classNames(`ImageCardCLVerticalTextInner`)}>{children}</div>
            </div>
          </div>
        </Col>
      ) : (
        <div className={classNames(`ImageCardCL`, `mb-${marginBottom}`, className)}>
          <Row className={classNames(`ImageCardCLOuterRow`, `d-flex`, `justify-content-center`)}>
            <Col xs="12" className={classNames(`ImageCardCLOuterCol`)}>
              <div
                className={classNames(
                  `ImageCardCLWrap`,
                  `background-${bgColor}`,
                  `text-${textColor}`,
                  `rounded`,
                  `box-shadow-${boxShadow}`,
                  `d-flex`,
                  `flex-column`
                )}
              >
                <Row className={classNames(`d-flex`, `align-items-stretch`, `m-0`, `w-100`)}>
                  <Col xs="12" lg={imageColLg} className={classNames(`ImageCardCLColImageWrap`, `px-0`)}>
                    <img
                      className={classNames(`ImageCardCLColImage`, `img-${imageFit}  `)}
                      src={image}
                      alt={imageAlt}
                    />
                  </Col>
                  <Col
                    xs="12"
                    lg={textColLg}
                    className={classNames(
                      `ImageCardCLColText`,
                      `p-4`,
                      `text-center`,
                      `text-lg-left`,
                      `d-flex`,
                      `flex-column`
                    )}
                  >
                    <div className={classNames(`ImageCardCLColTextInner`, `my-auto`)}>{children}</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
