import React from 'react';
import {Col, Row} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import './TestimonialCL.scss';

export default function TestimonialCL({
  id,
  text,
  cite,
  image,
  style = 'default',
  citeColor = 'black',
  citeLocation = 'outside',
  className
}) {
  const testimonialCite = <ReactMarkdown className={classNames(`testimonialBubbleCLName`)} source={cite} />;

  return (
    <div id={`testimonial-${id}`} className={classNames(`testimonialBubbleCL`, className)}>
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs="12">
          <div className={classNames(`testimonialBubbleCLContent`)}>
            <div
              className={classNames(
                `testimonialBubbleCLCopy`,
                style === 'light' ? `testimonialBubbleCLCopyLight` : `testimonialBubbleCLCopyDefault`,
                `background-${style === 'light' ? 'white' : 'light-gray'}`,
                `text-black`
              )}
            >
              <ReactMarkdown className={classNames(`testimonialBubbleCLText`)} source={text} />
              {citeLocation === 'inside' && <div className="mt-3">{testimonialCite}</div>}
            </div>
            <div className={classNames(`testimonialBubbleCLCite`, `text-${citeColor}`)}>
              {image && image.url ? (
                <div className="d-flex align-items-center">
                  <div className={classNames(`testimonialBubbleCLImage`)}>
                    <img
                      className={classNames(`rounded-circle`, `box-shadow-black-40`)}
                      src={image.url}
                      alt={image.alt}
                      width={image.width}
                      height={image.height}
                    />
                  </div>
                  {citeLocation != 'inside' && testimonialCite}
                </div>
              ) : (
                <>{citeLocation != 'inside' && testimonialCite}</>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
