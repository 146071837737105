import React from 'react';
import {Col, Container, Row, Button} from 'reactstrap';
import queryString from 'query-string';
import classNames from 'classnames';

import useBalanceText from 'utils/useBalanceText';
import useBeacon from 'utils/useBeacon';
import {useUserState} from 'contexts/user';

import PageMeta from 'components/PageMeta';
import Video from 'components/Video';
import {SalesDisclaimer} from 'components/SalesCta';
import CheckoutButton from 'components/CheckoutButton';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {TestimonialBubble} from 'components/Testimonial';
import {SpeakerListInfo} from 'components/SpeakerList';
import {BonusAccordion} from 'components/BonusList';
import GuaranteeBox from 'components/Guarantee';
import Footer from 'components/Footer';

//@ts-ignore
import {frsSpeakers, frsBonus} from 'data/frs.json';
import SectionCL from 'components/ComponentLibrary/SectionCL';

export default function ({product = 'ppt', ...props}) {
  const productID = 'frs22';
  const productSku = 'frs22-upsell';
  const productName = '2022 Food Revolution Summit Empowerment Package';
  const productSlug = 'frs22-upsell';
  const productValue = 47;

  const {
    OrderId = '',
    OfferSlug = '',
    UpSellId = ''
  } = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {};
  const successUrl = `${process.env.CHECKOUT_URL}/${OfferSlug}/${OrderId}/${UpSellId}/accept`;
  const declineUrl = `${process.env.CHECKOUT_URL}/${OfferSlug}/${OrderId}/${UpSellId}/decline`;

  function UpsellSection({
    id,
    sectionHeading = undefined,
    angle = 'bottom',
    className = undefined,
    order = undefined,
    trees = false,
    successUrl,
    declineUrl,
    ...props
  }) {
    console.log(successUrl, declineUrl);
    return (
      <SectionCL
        id={id}
        color="purple"
        angle={angle}
        className={classNames(`offer-box`, className)}
        order={order}
        type="triangle"
      >
        <Container>
          <Row>
            <Col>
              {sectionHeading ? <div className="text-white text-center mb-5">{sectionHeading}</div> : null}
              <div className="background-white rounded text-center p-4 p-lg-5">
                <h4 className="section-heading">One-Time Offer — Save 75%!</h4>
                <h5 className="mt-0">Food Revolution Summit Empowerment Package - Volume XI</h5>
                <Row className="justify-content-center">
                  <Col lg="10">
                    <img
                      src="https://cdn.foodrevolution.org/frs22/frs22-productimage-v20220301-nologo.png"
                      width="1500"
                      height="672"
                      alt="2022 Food Revolution Summit Empowerment Package graphic"
                    />
                  </Col>
                </Row>
                <h2 className="mt-3 mb-0">
                  <del>$197</del>
                </h2>
                <h2 className="text-green text-h1 mt-0 mb-3">Now only: $47</h2>

                <div className="upsell-box-confirm">
                  <div className="mb-3">
                    <CheckoutButton
                      className="offer-box-button"
                      sku={productSku}
                      price={Number(productValue).toFixed(2)}
                      slug={productSlug}
                      btnText="Yes, Add to Order"
                      badge
                      checkoutUrl={process.env.CHECKOUT_URL ?? ''}
                      href={successUrl}
                    />
                  </div>
                  <div className="upsell-box-text">
                    <p className="balance-text">
                      Clicking “Yes” above will add the <b>Empowerment Package</b> to your purchase now for an
                      additional $47.
                    </p>
                    <p className="balance-text">
                      Your purchase (and 75% savings) comes with a risk-free, 60-day, money-back guarantee.
                    </p>
                  </div>
                </div>

                <div className="upsell-box-no">
                  <div className="mb-3">
                    <Button className="btn-upsell-gray mt-4 mb-3" href={declineUrl}>
                      No, thanks.
                    </Button>
                  </div>
                  <div className="upsell-box-no-text">
                    Clicking the “No” button above will take you to the products already in your cart.
                  </div>
                </div>
                <SalesDisclaimer noGuaranteeText />
              </div>
              {trees && (
                <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
                  <Col xs="11" lg="8" className="mb-4 mb-lg-0">
                    <h6 className="section-only-heading text-center text-lg-start text-400">
                      For every new order, we make a donation to Trees for the Future, enabling them to plant another
                      organic fruit or nut tree in a low-income community.
                    </h6>
                  </Col>
                  <Col xs="11" lg="3" className="text-center">
                    <img
                      className={classNames(`logo-trees`)}
                      alt="Trees for the Future logo"
                      src="https://cdn.foodrevolution.org/global/trees-logo-white.png"
                      width={1080}
                      height={442}
                      style={{maxHeight: '100px', width: 'auto'}}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </SectionCL>
    );
  }

  //useBeacon();
  useBalanceText();
  return (
    <div className="page page-nosocial">
      <PageMeta
        title="Experience the Full Power of the Empowerment Package"
        description="Discover the ultimate transformation with the Food Revolution Summit Empowerment Package."
      />

      <div className="page-content">
        <SectionCL id="us-frs-header" className="text-white text-center" color="black" type="triangle" order="first">
          <Container>
            <Row className="mb-4">
              <Col>
                {((product) => {
                  switch (product) {
                    case 'ul':
                      return (
                        <>
                          <h3 className="section-heading mb-3">
                            Before you dive into Unlocking Longevity, check out this video.
                          </h3>
                          <h5 className="m-0">(You won’t see this special offer again!)</h5>
                        </>
                      );
                    case 'ppt':
                      return (
                        <>
                          <h3 className="section-heading mb-3">
                            Before you dive into Plant-Powered & Thriving, check out this video.
                          </h3>
                          <h5 className="m-0">(You won’t see this special offer again!)</h5>
                        </>
                      );
                    default:
                      return null;
                  }
                })(product)}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <Video
                  url={
                    {
                      ul: 'https://www.youtube.com/watch?v=9yKIXxs7LJY',
                      ppt: 'https://www.youtube.com/watch?v=F7UeCxh7V_M'
                    }[product]
                  }
                  label={`${product} - FRS Upsell`}
                  playing
                />
              </Col>
            </Row>
            {((product) => {
              switch (product) {
                case 'ul':
                case 'ppt':
                  return (
                    <Row className="text-white text-center mt-5">
                      <Col>
                        <h3 className="section-heading">
                          Imagine Knowing Exactly What to Eat to Help Protect Your Health… for Life
                        </h3>
                        <h5 className="mt-0 text-400">
                          The Food Revolution Summit Empowerment Package Is the #1 Way to Catapult Your Energy, Prevent
                          Disease, Fight Climate Change, and Build Healthy Habits That Last a Lifetime.
                        </h5>
                        <p className="mb-0">2 Million People Served</p>
                      </Col>
                    </Row>
                  );
                default:
                  return null;
              }
            })(product)}
          </Container>
        </SectionCL>

        <UpsellSection
          id="us-frs-cta-1"
          sectionHeading={
            <>
              <h4 className="section-heading">
                Let John Robbins bring you the latest breakthroughs, as he interviews 24 of the top food experts on the
                planet. 100% audio, for easy listening any time or place you like. All yours instantly (for a price
                you’ll never see again).
              </h4>
            </>
          }
          trees
          successUrl={successUrl}
          declineUrl={declineUrl}
        />

        <SectionCL
          id="us-frs-testimonials-1"
          color={((product) => {
            switch (product) {
              case 'ppt':
                return 'light-gray';
              default:
                return 'white';
            }
          })(product)}
          type="triangle"
        >
          <Container>
            <Row>
              <Col>
                {['frs-sheila-m', 'frs-pamela', 'frs-rajiv-b', 'frs-diane-b', 'frs-aleece-d'].map((key, i) => (
                  <TestimonialBubble
                    bgColor={((product) => {
                      switch (product) {
                        case 'ppt':
                          return 'white';
                        default:
                          return 'light-gray';
                      }
                    })(product)}
                    id={key}
                    key={key}
                    className={i != 4 && `mb-5`}
                  />
                ))}
                <p className="balance-text text-center text-small mt-4">
                  <i>
                    Many Summit participants have adopted the concepts, regimens, and dietary patterns that our
                    panelists advocate. Each of them is unique. And so are you! Your experiences will vary, of course.
                    And no, we never offer compensation for these statements!
                  </i>
                </p>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        {((product) => {
          switch (product) {
            case 'ppt':
              return (
                <>
                  <SectionCL
                    id="us-frs-industrialized"
                    color="white"
                    imgPosition="right"
                    //@ts-ignore
                    imgUrl={require('static/frs/1202939384.jpg')}
                    type="triangle"
                  >
                    <h2 className="section-heading">
                      The Plant-Powered and Thriving course will give you a strong foundation…
                    </h2>
                    <p>
                      and now, with the Food Revolution Summit Empowerment Package, you can take your understanding and
                      your results to the next level. This exclusive offer is designed to provide you with additional
                      resources, expert insights, and practical tools that will help you solidify and expand upon what
                      you learn in the course.
                    </p>
                  </SectionCL>
                </>
              );
            default:
              return (
                <>
                  <SectionCL
                    id="us-frs-industrialized"
                    color="light-gray"
                    imgPosition="left"
                    //@ts-ignore
                    imgUrl={require('static/frs/1202939384.jpg')}
                    type="triangle"
                  >
                    <h2 className="section-heading">The modern industrialized diet can kill.</h2>
                    <p>
                      Why? It weakens your immune system and creates the foundations for diseases like heart disease,
                      cancer, type 2 diabetes, and more.
                    </p>
                    <p>This isn’t an exaggeration. It’s a scientifically proven fact.</p>
                    <p>
                      The good news is that you CAN prevent and <i>even reverse</i> these and other conditions.
                    </p>
                    <p>
                      <b>And it all starts with the food on your plate.</b>
                    </p>
                  </SectionCL>
                  <SectionCL
                    id="us-frs-difference"
                    color="white"
                    imgPosition="right"
                    //@ts-ignore
                    imgUrl={require('static/frs/628097352.jpg')}
                    type="triangle"
                  >
                    <h4 className="section-heading">With your food choices, you get to take the power back.</h4>
                    <p>Every bite can be a vote for your health and the world you want.</p>
                    <p>
                      Every bite can be a vote for preventing disease and for staying vibrant, productive, and on top of
                      your game.
                    </p>
                    <p>
                      If you’re ready for grounded hope with{' '}
                      <b>
                        <i>real solutions</i> that are powerful enough to shift the course of humanity,
                      </b>{' '}
                      you’re in the right place.
                    </p>
                    <p>
                      <b>You CAN make a difference — for your future and the future of our planet.</b>
                    </p>
                  </SectionCL>
                </>
              );
          }
        })(product)}

        <SectionCL
          id="us-frs-enough"
          color="light-gray"
          imgPosition="left"
          //@ts-ignore
          imgUrl={require('static/frs/1329782829.jpg')}
          type="triangle"
        >
          {((product) => {
            switch (product) {
              case 'ppt':
                return (
                  <>
                    <h4 className="section-heading mb-2">
                      Join the millions of people who are taking their plant-powered journey to the next level:
                    </h4>
                    <h4 className="mt-0">IT’S TIME TO SAY: I DESERVE MORE!</h4>
                    <h2 className="mt-0">Elevate Your Choices. Maximize Your Health and Happiness.</h2>
                    <p>
                      You’ve taken a powerful step with Plant-Powered and Thriving, but there’s always more to learn and
                      experience. With so much beyond our control, now is the time to double down on what you can
                      control — starting with what’s on your plate.
                    </p>
                    <p>
                      <b>Are you ready to optimize your plant-powered lifestyle?</b>
                    </p>
                    <p>Every bite you take and every purchase you make is a vote for a safer and healthier future.</p>
                    <p>
                      <b>
                        The same food choices that help you <i>prevent and even reverse chronic illness</i> can also
                        give you more energy, deeper sleep, and a clearer mind, and allow you to enjoy life more.
                      </b>
                    </p>
                  </>
                );
              default:
                return (
                  <>
                    <h4 className="section-heading mb-2">
                      Join the millions of people who are rising up with us and saying:
                    </h4>
                    <h4 className="mt-0">ENOUGH IS ENOUGH!</h4>
                    <h2 className="mt-0">Make the Best Choices. Enjoy the Best Health of Your Life.</h2>
                    <p>
                      In these times, a great deal is out of our control, which makes it all the more critical to make
                      smart choices each day. One thing you DO get to decide, which can make a WORLD of difference, is
                      what you eat.
                    </p>
                    <p>
                      <b>So, are you eating the RIGHT vegetables, fruits, and whole foods?</b>
                    </p>
                    <p>Every bite you take and every purchase you make is a vote for a safer and healthier future.</p>
                    <p>
                      <b>
                        The same food choices that help you <i>prevent and even reverse chronic illness</i> can also
                        give you more energy, deeper sleep and a clearer mind, and allow you to enjoy life more.
                      </b>
                    </p>
                  </>
                );
            }
          })(product)}
        </SectionCL>

        <SectionCL id="" color="white" type="triangle">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10">
                <h3 className="my-4">With the Empowerment Package, you’ll...</h3>
                <IconList>
                  <IconListItem>
                    Get <b>scientifically grounded insights</b> to protect your health and your family’s health, from
                    the world’s top food experts.
                  </IconListItem>
                  <IconListItem>
                    <b>Discover how to enjoy vibrant health and boost immunity,</b> so your body can ward off disease,
                    enjoy cardiovascular health, boost your brain function, and more.
                  </IconListItem>
                  <IconListItem>
                    <b>Gain TRULY life-saving wisdom and practical tools</b> to help you turn cutting-edge knowledge
                    into <b>life-changing ACTION.</b>
                  </IconListItem>
                </IconList>
                <p>
                  You’ll also become a source of wisdom and support to the people you love, so they stay safe and
                  healthy, too.
                </p>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="" color="light-gray" className="text-center" type="triangle">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">The Summit Story</h2>
                <p>
                  Starting in 2012, John Robbins conducted hundreds of interviews with the world’s leading food and
                  health experts. These interviews inspired more than 2 million people with the power of diet and
                  lifestyle to change their lives. Recently the Summit moved to a Docuseries format, with John’s son
                  (and FRN’s CEO, Ocean Robbins) and other FRN team members, conducting the Summit interviews. This
                  volume XI collection is the culmination of 11 years of Summits - the last round of brilliant
                  interviews conducted personally by John Robbins.
                </p>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="us-frs-speakers" color="white" type="triangle">
          <Container>
            <Row className="text-center mb-4">
              <Col>
                <h2 className="section-heading text-400 mb-3">
                  {((product) => {
                    switch (product) {
                      case 'ppt':
                        return (
                          <>
                            <b>Strengthen Your Health & Make a Real Difference</b> With These World-Renowned Experts
                          </>
                        );
                      default:
                        return (
                          <>
                            <b>Protect Your Health & Take a Stand for the Planet</b> With These World-Renowned Experts
                          </>
                        );
                    }
                  })(product)}
                </h2>
                <p className="mb-0">
                  All personally interviewed by John Robbins, the best-selling author of <i>Diet for A New America</i>
                </p>
              </Col>
            </Row>
            <Row>
              <SpeakerListInfo speakerList={frsSpeakers} modalLink />
            </Row>
            <Row className="text-center">
              <Col>
                <h3 className="section-heading">
                  Want to be a part of the solution on planet Earth? Want to turn your health around for the better?
                  Want to enjoy eating more vegetables and plant-based foods?
                </h3>
                <h3 className="m-0">
                  Find out how to do it right, so you can thrive, with the Summit Empowerment Package (Volume XI).
                </h3>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="us-frs-access" color="light-gray" type="triangle">
          <Container>
            <Row>
              <Col>
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle" //@ts-ignore
                      src={require('static/frs/1304728656.jpg')}
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">Unlimited Access to Recordings</h4>
                    <p className="mb-0">
                      What’s unique about these 25 on-demand audio interviews is having one place to get the expertise
                      of <i>New York Times</i> best-selling authors, award-winning doctors, researchers, holistic
                      surgeons, and healers who know what’s working right now. Access all the sessions whenever and
                      wherever you want.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img //@ts-ignore
                      src={require('static/frs/transcripts.png')}
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">Written Transcripts</h4>
                    <p className="mb-0">
                      Get access to every interview in written PDF form (including with Spanish translation option).
                      Read through the material quickly and find exactly what you’re looking for without having to
                      relisten to the whole session (unless you want to). You won’t find the transcripts anywhere else.
                      You can only get this time-saving resource as part of the Empowerment Package.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle" //@ts-ignore
                      src={require('static/frs/1292777576.jpg')}
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">Links to Scientific Studies Referenced in the Summit</h4>
                    <p className="mb-0">
                      We want you to be able to rely on more than just our word, so we’ve included links to many of the
                      double-blind, peer-reviewed studies you’ll hear about in the interviews. This is perfect if you
                      like to check sources, do your own research, or dive deeper into critical information.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle" //@ts-ignore
                      src={require('static/frs/everday-lentil-lunch.jpg')}
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">27 Delicious, Whole Foods, Plant-Based Recipes</h4>
                    <p className="mb-0">
                      Forget about eating rabbit food or the same 4 dishes over and over — prepare to ENJOY exciting new
                      recipes! You’ll get the entire collection of 27 delicious, whole foods, plant-based recipes.
                      Whether you’re new to plant-based eating or you’re already fluent in veggies, you’ll find
                      scrumptious recipes to delight your entire body.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle" //@ts-ignore
                      src={require('static/frs/1061636586.jpg')}
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">
                      $1,000+ in Bonus Gifts: Program Memberships, Recipe Collections,{' '}
                      <span className="text-nowrap">e-Books,</span> Seminars, & Courses
                    </h4>
                    <p className="mb-0">
                      Love learning and want years’ worth of extras so you can make progress even faster? You’ll get all
                      27 bonuses, generously provided by our summit experts. Pick where you want to dive deeper and keep
                      the momentum of the summit going all year.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle" //@ts-ignore
                      src={require('static/frs/ocean-robbins-square.jpg')}
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">Ocean Robbins’ Post-Interview Top Takeaways</h4>
                    <p className="mb-0">
                      Get the “greatest hits” from each of the summit sessions, and some follow-up questions answered,
                      with recordings of live commentary from Ocean Robbins — best-selling author, lifelong food justice
                      activist, and host of the Food Revolution Summit. This is like getting a behind-the-scenes
                      download from the director!
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="11" sm="10" md="8" lg="3" className="mb-4 mb-lg-0">
                    <img //@ts-ignore
                      src={require('static/frs/guest-experts.png')}
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">
                      “Ask the Experts” Q&A Call With John & Ocean, Joined by Joel{' '}
                      <span className="text-nowrap">Kahn, MD,</span> and Julieanna{' '}
                      <span className="text-nowrap">Hever, RD</span>
                    </h4>
                    <p className="mb-0">
                      We took the biggest questions from our community and gave them to our health experts. Wondering if
                      allergies can be reversed? What are the best foods to eat to help address chronic inflammation? Is
                      goat milk a good way to get calcium? This practical and informative event is designed to answer
                      YOUR questions and to help you put the food revolution into action in your life.
                    </p>
                  </Col>
                </Row>
                <hr className="border-color-black-25 my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img
                      className="rounded-circle" //@ts-ignore
                      src={require('static/frs/1144444285.jpg')}
                    />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="mt-0">Ability to Share With Friends & Family</h4>
                    <p className="mb-0">
                      Know someone who’d benefit from a specific session or resource? We all want our loved ones to be
                      as healthy as possible, which is why we want you to share the summit with the people in your life.
                      This is how the food revolution grows — and we want as many people on board as possible!
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="us-frs-testimonials-2" color="white" type="triangle">
          <Container>
            <Row>
              <Col>
                <TestimonialBubble id="frs-joan-s" />
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="us-frs-bonuses" color="light-gray" type="triangle">
          <Container>
            <Row className="text-center">
              <Col>
                <h3 className="section-heading">And There’s More: You’ll Take Home These Bonuses!</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <BonusAccordion bonuslist={frsBonus} borderColor="black-25" />
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <UpsellSection
          id="us-frs-cta-2"
          sectionHeading={
            <>
              <h3 className="section-heading">One-Time Offer</h3>
              <h4 className="mt-0">
                Act now and you’ll own the entire, insight-packed Food Revolution Summit (Volume XI) for 75% off.
              </h4>
            </>
          }
          successUrl={successUrl}
          declineUrl={declineUrl}
        />

        <SectionCL id="us-frs-guarantee" color="light-gray" type="triangle">
          <Container>
            <Row>
              <Col>
                <GuaranteeBox title={<>You’re Protected by Our 60-Day, Unconditional, Money-Back Guarantee</>}>
                  <p>
                    Try it out. If you aren't completely blown away, or even if you just don’t like it, email us at{' '}
                    <a href="mailto:support@foodrevolution.org">support@foodrevolution.org</a>, or phone us at (831)
                    824-4779 any time within 60 days of purchase for a prompt and courteous refund.
                  </p>
                  <p>
                    This means you can even go through the entire package, listen to every interview, access all the
                    bonuses, and make every single mouth-watering recipe. If it doesn’t rock your world, you get every
                    penny back. That’s how confident we are that you’ll LOVE the Empowerment Package.
                  </p>
                  <p>
                    PLUS, if for any reason you request a refund, everything that you’ve downloaded is still yours to
                    keep. That’s why we call our guarantee “better than money back.”
                  </p>
                </GuaranteeBox>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <UpsellSection
          id="us-frs-cta-3"
          angle="none"
          order="last"
          sectionHeading={
            <>
              <h3 className="section-heading">
                Take a stand for the health you want, and the future our children deserve. Get Your Empowerment Package
                Today.
              </h3>
              <p>All totally digital! No wasted plastic, paper, or landfill space.</p>
              <h5 className="mt-0">Thank you for your support!</h5>
            </>
          }
          successUrl={successUrl}
          declineUrl={declineUrl}
        />
      </div>

      <Footer />
    </div>
  );
}
